import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import Dealer from "@/types/Dealers/Dealer"
import PaginateDealer from "@/types/Dealers/PaginateDealer"
import DealerCountry from "@/types/Dealers/DealerCountry"
import DealerProvince from "@/types/Dealers/DealerProvince"

export default class DealerApi extends DiamondBaseAPI {
  static listCountries(): Promise<ResponseBase<DealerCountry>> {
    return this.api.get("dealer-country").then(({ data }) => data)
  }

  static listProvince(countryId: number): Promise<ResponseBase<DealerProvince>> {
    return this.api
      .get("dealer-province", {
        params: {
          country: countryId
        }
      })
      .then(({ data }) => data)
  }

  static listDealers(
    page: number,
    country: number,
    province: number,
    size = 10
  ): Promise<ResponseBase<PaginateDealer>> {
    return this.api
      .get("/dealer", {
        params: {
          page,
          size,
          province,
          country
        }
      })
      .then(({ data }) => data)
  }

  static listModernTrades(): Promise<ResponseBase<Dealer>> {
    return this.api.get("/dealer-modern-trade").then(({ data }) => data)
  }

  static listLeadingTrades(): Promise<ResponseBase<Dealer>> {
    return this.api.get("/dealer-lead").then(({ data }) => data)
  }
}
