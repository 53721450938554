import DiamondFile from "@/types/DiamondFile"
import { JsonProperty, Serializable } from "typescript-json-serializer"
import { fullWidth } from "@/types/helper"

@Serializable()
export default class Dealer {
  @JsonProperty() id!: number

  @JsonProperty("shop_name") shopName!: string

  @JsonProperty() tel!: string

  @JsonProperty() address!: string

  @JsonProperty() lead!: number

  @JsonProperty("modern_trade") modernTrade!: number

  @JsonProperty() lat!: number

  @JsonProperty() lng!: number

  @JsonProperty("place_name") placeName!: string

  @JsonProperty("place_id") placeId!: string

  @JsonProperty() link!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  @JsonProperty({ name: "i_frame", beforeDeserialize: fullWidth }) iFrame!: string
}
